@font-face {
    font-family: "sb-font-icon";
    src: url("../fonts/sb-font-icon/sb-font-icon.eot?ee84e14dc7dd7a5808b450dc82703230?#iefix") format("embedded-opentype"),
url("../fonts/sb-font-icon/sb-font-icon.woff2?ee84e14dc7dd7a5808b450dc82703230") format("woff2"),
url("../fonts/sb-font-icon/sb-font-icon.woff?ee84e14dc7dd7a5808b450dc82703230") format("woff"),
url("../fonts/sb-font-icon/sb-font-icon.ttf?ee84e14dc7dd7a5808b450dc82703230") format("truetype"),
url("../fonts/sb-font-icon/sb-font-icon.svg?ee84e14dc7dd7a5808b450dc82703230#sb-font-icon") format("svg");
}

[class^="sbi-"], [class*=" sbi-"] {
    display: inline-flex;
}

[class^="sbi-"]:before, [class*=" sbi-"]:before {
    font-family: sb-font-icon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.sbi-active-channels:before {
    content: "\f101";
}
.sbi-alert:before {
    content: "\f102";
}
.sbi-analogoutput-filled:before {
    content: "\f103";
}
.sbi-analogoutput:before {
    content: "\f104";
}
.sbi-anchor:before {
    content: "\f105";
}
.sbi-angle:before {
    content: "\f106";
}
.sbi-arrow-down:before {
    content: "\f107";
}
.sbi-arrow-left:before {
    content: "\f108";
}
.sbi-arrow-right:before {
    content: "\f109";
}
.sbi-arrow-up:before {
    content: "\f10a";
}
.sbi-backspace-filled:before {
    content: "\f10b";
}
.sbi-backspace:before {
    content: "\f10c";
}
.sbi-battery-empty:before {
    content: "\f10d";
}
.sbi-battery-full:before {
    content: "\f10e";
}
.sbi-battery-half:before {
    content: "\f10f";
}
.sbi-battery-quarter:before {
    content: "\f110";
}
.sbi-battery-three-quarters:before {
    content: "\f111";
}
.sbi-big-attention:before {
    content: "\f112";
}
.sbi-big-error:before {
    content: "\f113";
}
.sbi-big-hint:before {
    content: "\f114";
}
.sbi-big-info:before {
    content: "\f115";
}
.sbi-big-success:before {
    content: "\f116";
}
.sbi-big-warning:before {
    content: "\f117";
}
.sbi-bluetooth:before {
    content: "\f118";
}
.sbi-calibration:before {
    content: "\f119";
}
.sbi-camera-filled:before {
    content: "\f11a";
}
.sbi-camera:before {
    content: "\f11b";
}
.sbi-channel:before {
    content: "\f11c";
}
.sbi-checkhook:before {
    content: "\f11d";
}
.sbi-chevron-double-down:before {
    content: "\f11e";
}
.sbi-chevron-double-left:before {
    content: "\f11f";
}
.sbi-chevron-double-right:before {
    content: "\f120";
}
.sbi-chevron-double-up:before {
    content: "\f121";
}
.sbi-chevron-down:before {
    content: "\f122";
}
.sbi-chevron-left:before {
    content: "\f123";
}
.sbi-chevron-right:before {
    content: "\f124";
}
.sbi-chevron-up:before {
    content: "\f125";
}
.sbi-close:before {
    content: "\f126";
}
.sbi-configure:before {
    content: "\f127";
}
.sbi-copy:before {
    content: "\f128";
}
.sbi-cube:before {
    content: "\f129";
}
.sbi-curve:before {
    content: "\f12a";
}
.sbi-data-processing:before {
    content: "\f12b";
}
.sbi-date:before {
    content: "\f12c";
}
.sbi-datetime:before {
    content: "\f12d";
}
.sbi-device-comoneo:before {
    content: "\f12e";
}
.sbi-device-imm:before {
    content: "\f12f";
}
.sbi-device-impulse-tool:before {
    content: "\f130";
}
.sbi-device-indicating-torque:before {
    content: "\f131";
}
.sbi-device-inspection:before {
    content: "\f132";
}
.sbi-device-labeling:before {
    content: "\f133";
}
.sbi-device-maxymos:before {
    content: "\f134";
}
.sbi-device-nutrunner:before {
    content: "\f135";
}
.sbi-device-setting-torque:before {
    content: "\f136";
}
.sbi-device-thermic:before {
    content: "\f137";
}
.sbi-device-transport:before {
    content: "\f138";
}
.sbi-digital-hub:before {
    content: "\f139";
}
.sbi-display:before {
    content: "\f13a";
}
.sbi-dots-horizontal:before {
    content: "\f13b";
}
.sbi-dots-vertical:before {
    content: "\f13c";
}
.sbi-download-filled:before {
    content: "\f13d";
}
.sbi-drag-indicator:before {
    content: "\f13e";
}
.sbi-export-filled:before {
    content: "\f13f";
}
.sbi-export:before {
    content: "\f140";
}
.sbi-eye-slash:before {
    content: "\f141";
}
.sbi-eye:before {
    content: "\f142";
}
.sbi-factory-reset-filled:before {
    content: "\f143";
}
.sbi-factory-reset:before {
    content: "\f144";
}
.sbi-file-csv:before {
    content: "\f145";
}
.sbi-file-doc:before {
    content: "\f146";
}
.sbi-file-exe:before {
    content: "\f147";
}
.sbi-file-gif:before {
    content: "\f148";
}
.sbi-file-jpg:before {
    content: "\f149";
}
.sbi-file-log:before {
    content: "\f14a";
}
.sbi-file-other:before {
    content: "\f14b";
}
.sbi-file-pdf:before {
    content: "\f14c";
}
.sbi-file-png:before {
    content: "\f14d";
}
.sbi-file-ppt:before {
    content: "\f14e";
}
.sbi-file-rar:before {
    content: "\f14f";
}
.sbi-file-sql:before {
    content: "\f150";
}
.sbi-file-tiff:before {
    content: "\f151";
}
.sbi-file-txt:before {
    content: "\f152";
}
.sbi-file-xls:before {
    content: "\f153";
}
.sbi-file-zip:before {
    content: "\f154";
}
.sbi-filter-highpass:before {
    content: "\f155";
}
.sbi-filter-lowpass:before {
    content: "\f156";
}
.sbi-filter-notch:before {
    content: "\f157";
}
.sbi-filter:before {
    content: "\f158";
}
.sbi-go-to:before {
    content: "\f159";
}
.sbi-help-info:before {
    content: "\f15a";
}
.sbi-help:before {
    content: "\f15b";
}
.sbi-key:before {
    content: "\f15c";
}
.sbi-language:before {
    content: "\f15d";
}
.sbi-loader:before {
    content: "\f15e";
}
.sbi-lock:before {
    content: "\f15f";
}
.sbi-maximize:before {
    content: "\f160";
}
.sbi-measurement-setup:before {
    content: "\f161";
}
.sbi-menu:before {
    content: "\f162";
}
.sbi-moldingmachine-control:before {
    content: "\f163";
}
.sbi-moldingmachine-production:before {
    content: "\f164";
}
.sbi-moldingmachine-statistics:before {
    content: "\f165";
}
.sbi-multiflow:before {
    content: "\f166";
}
.sbi-nav-analysis:before {
    content: "\f167";
}
.sbi-nav-back:before {
    content: "\f168";
}
.sbi-nav-browser:before {
    content: "\f169";
}
.sbi-nav-colors:before {
    content: "\f16a";
}
.sbi-nav-components:before {
    content: "\f16b";
}
.sbi-nav-control:before {
    content: "\f16c";
}
.sbi-nav-dashboard:before {
    content: "\f16d";
}
.sbi-nav-dataacquisition:before {
    content: "\f16e";
}
.sbi-nav-device-como:before {
    content: "\f16f";
}
.sbi-nav-device:before {
    content: "\f170";
}
.sbi-nav-devices:before {
    content: "\f171";
}
.sbi-nav-favorites:before {
    content: "\f172";
}
.sbi-nav-form:before {
    content: "\f173";
}
.sbi-nav-home:before {
    content: "\f174";
}
.sbi-nav-icons:before {
    content: "\f175";
}
.sbi-nav-info:before {
    content: "\f176";
}
.sbi-nav-iot:before {
    content: "\f177";
}
.sbi-nav-jbeam:before {
    content: "\f178";
}
.sbi-nav-layouts:before {
    content: "\f179";
}
.sbi-nav-mold:before {
    content: "\f17a";
}
.sbi-nav-monitoring:before {
    content: "\f17b";
}
.sbi-nav-notifications:before {
    content: "\f17c";
}
.sbi-nav-production:before {
    content: "\f17d";
}
.sbi-nav-settings:before {
    content: "\f17e";
}
.sbi-nav-site-setup:before {
    content: "\f17f";
}
.sbi-nav-test:before {
    content: "\f180";
}
.sbi-nav-user-management:before {
    content: "\f181";
}
.sbi-nav-user:before {
    content: "\f182";
}
.sbi-nav-vehicle-measurement:before {
    content: "\f183";
}
.sbi-oauth-clients:before {
    content: "\f184";
}
.sbi-pencil-filled:before {
    content: "\f185";
}
.sbi-pencil:before {
    content: "\f186";
}
.sbi-player-pause:before {
    content: "\f187";
}
.sbi-player-play:before {
    content: "\f188";
}
.sbi-player-record:before {
    content: "\f189";
}
.sbi-player-stop:before {
    content: "\f18a";
}
.sbi-player-trigger:before {
    content: "\f18b";
}
.sbi-plus:before {
    content: "\f18c";
}
.sbi-replace:before {
    content: "\f18d";
}
.sbi-search:before {
    content: "\f18e";
}
.sbi-sensor-acceleration-filled:before {
    content: "\f18f";
}
.sbi-sensor-charge-filled:before {
    content: "\f190";
}
.sbi-sensor-distance-filled:before {
    content: "\f191";
}
.sbi-sensor-force-filled:before {
    content: "\f192";
}
.sbi-sensor-generic-filled:before {
    content: "\f193";
}
.sbi-sensor-iepe-filled:before {
    content: "\f194";
}
.sbi-sensor-machinesigna-filled:before {
    content: "\f195";
}
.sbi-sensor-pressure-filled:before {
    content: "\f196";
}
.sbi-sensor-temperature-filled:before {
    content: "\f197";
}
.sbi-sensor-time-filled:before {
    content: "\f198";
}
.sbi-sensor-torque-filled:before {
    content: "\f199";
}
.sbi-sensor-voltage-filled:before {
    content: "\f19a";
}
.sbi-sensor:before {
    content: "\f19b";
}
.sbi-setup:before {
    content: "\f19c";
}
.sbi-shift-filled:before {
    content: "\f19d";
}
.sbi-shift:before {
    content: "\f19e";
}
.sbi-signal-storage:before {
    content: "\f19f";
}
.sbi-sitemap:before {
    content: "\f1a0";
}
.sbi-snowflake:before {
    content: "\f1a1";
}
.sbi-switch-over:before {
    content: "\f1a2";
}
.sbi-tau:before {
    content: "\f1a3";
}
.sbi-time:before {
    content: "\f1a4";
}
.sbi-toast-close:before {
    content: "\f1a5";
}
.sbi-toast-error:before {
    content: "\f1a6";
}
.sbi-toast-info:before {
    content: "\f1a7";
}
.sbi-toast-success:before {
    content: "\f1a8";
}
.sbi-toast-warning:before {
    content: "\f1a9";
}
.sbi-torque:before {
    content: "\f1aa";
}
.sbi-trash-filled:before {
    content: "\f1ab";
}
.sbi-trash:before {
    content: "\f1ac";
}
.sbi-triangle-down:before {
    content: "\f1ad";
}
.sbi-triangle-left:before {
    content: "\f1ae";
}
.sbi-triangle-right:before {
    content: "\f1af";
}
.sbi-triangle-up:before {
    content: "\f1b0";
}
.sbi-user:before {
    content: "\f1b1";
}
.sbi-virtual-channel-filled:before {
    content: "\f1b2";
}
.sbi-virtual-channel:before {
    content: "\f1b3";
}
.sbi-zoom-in:before {
    content: "\f1b4";
}
.sbi-zoom-out:before {
    content: "\f1b5";
}
